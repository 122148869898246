<template>
  <div class="page" v-loading="loading">
    <el-container style="height: 1000px; border: 1px solid #eee;margin-top: 30px;">
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <asideLeft :errorArr="errorArr" :parperArr="parperArr" :allNumberArr="allNumberArr"  :allQuestionArr="allQuestionArr"  @changeMaodian="changeMaodian"></asideLeft>
      </el-aside>
      <el-container>
        <!--				<el-header>-->
        <!--					<headerDetails></headerDetails>-->
        <!--				</el-header>-->
        <el-main>
          <mainDetails :questionAndAnswer="questionAndAnswer" :paperTitle="paperTitle" :maodian="maodian"></mainDetails>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import asideLeft from "./components/asideLeft.vue"
import headerDetails from "./components/headerDetails.vue"
import mainDetails from "./components/mainDetails.vue"

import {
  examinationRecordDetails
} from "@/api/index.js"

export default {
  components: {
    asideLeft,
    headerDetails,
    mainDetails,
  },
  data() {
    return {
      loading: false,
      answerArr:null,
      parperArr: {},
      questionAndAnswer: [],
      allNumberArr: [],
      errorArr: [],
      allQuestionArr: [],
      paperTitle: '',
      maodian: '#1'
    }
  },
  methods: {
    changeMaodian(idName) {
      this.maodian = idName
    },
    getInfo(id) {
      this.loading = true;
      let param = {
        id: id
      }
      examinationRecordDetails(param).then(res => {
        if (res.code == "0000") {
          this.allQuestionArr = res.data.paper.titleItems
          this.parperArr = res.data.paper;
          this.answerArr = res.data.answerArr;
          this.errorArr = res.data.answer.errorItems;
          let numberArr = []
          res.data.paper.titleItems.forEach(item => {
            item.questionItems.forEach(item1 => {
              numberArr.push(item1.itemOrder)
            })
          })
          this.allNumberArr = numberArr
          let allData = {};
          let oneItem = [];
          allData.name = res.data.paper.name;
          this.paperTitle = res.data.paper.name
          allData.id = res.data.paper.id;
          allData.level = res.data.paper.level;
          allData.paperType = res.data.paper.paperType;
          allData.score = res.data.paper.score;
          allData.suggestTime = res.data.paper.suggestTime;
          allData.type = res.data.paper.type;
          allData.doTime = res.data.answer.doTime;
          allData.allRecord = res.data.paper.titleItems;
          res.data.paper.titleItems.forEach((item, index) => {
            item.questionItems.forEach((item1, index1) => {
              res.data.answer.answerItems.forEach((item2, index2) => {
                if (item1.id == item2.questionId) {
                  allData.allRecord[index].questionItems[index1].answerInfo = item2
                }
              })
            })
          })
          this.questionAndAnswer = allData.allRecord;
          this.loading = false
        } else {
          this.loading = false
          this.$router.push({
            path: '/examinationRecord'
          })
        }
      }).catch(() => {
        this.$router.push({
          path: '/examinationRecord'
        })
      });
    }
  },
  mounted() {
    this.getInfo(this.$route.query.id)
  }
}
</script>

<style lang="scss" scoped>
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
