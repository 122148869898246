<template>
	<div id="scrollDiv">
    <div class="titleStyle" style="margin-bottom: 30px;margin-top: 10px">
      <p >
        {{paperTitle}}
      </p>
    </div>
		<div v-for="(item,index) in questionAndAnswer" :key="index" >
			<div class="titleType">
				<p>
					{{item.name}}
				</p>
			</div>
			<div style="padding-bottom: 30px;margin-top: 10px;" v-for="(item1,index1) in item.questionItems"
				:key="index1">
				<a :id="'q' + item1.itemOrder">
					<radioSubject v-if="(item1.questionType == 0) || (item1.questionType == 1) "
						:subjectId="item1.itemOrder" :questionBody="item1" :isDis="true" :haveAnswer="true">
					</radioSubject>
					<checkSubject v-if="item1.questionType == 2" :subjectId="item1.itemOrder" :questionBody="item1"
						:isDis="true" :haveAnswer="true"></checkSubject>
					<programmingSubject v-if="item1.questionType == 3" :subjectId="item1.itemOrder"
						:questionBody="item1" :isDis="true" :haveAnswer="true">
					</programmingSubject>
					<programmingSubject v-if="item1.questionType == 4" :subjectId="item1.itemOrder"
						:questionBody="item1" :isDis="true" :haveAnswer="true">
					</programmingSubject>
					<programmingSubjectMore v-if="item1.questionType == 5" :subjectId="item1.itemOrder"
						:questionBody="item1" :isDis="true" :haveAnswer="true">
					</programmingSubjectMore>
				</a>
			</div>

			<el-divider></el-divider>
		</div>
	</div>
</template>

<script>
	import radioSubject from "@/components/radioSubject.vue"
	import checkSubject from "@/components/checkSubject.vue"
	import shortAnswerSubject from "@/components/shortAnswerSubject.vue"
	import programmingSubject from "@/components/programmingSubject.vue"
	import programmingSubjectMore from "@/components/programmingSubjectMore.vue"

	export default {
		watch: {
			maodian: {
				handler: function(n, o) {
					// console.log(this.maodian)

					var target = document.querySelector(this.maodian);

					// document.getElementById("scrollDiv").parentNode.scrollTo(0, (target.offsetTop - 40))

					this.scrollAnimation(document.getElementById("scrollDiv").parentNode.scrollTop, (target.offsetTop -
						180))
				},
				deep: true
			}
		},
		props: {
			questionAndAnswer: {
				type: Array,
				default: () => []
			},
			maodian: {
				type: String,
				default: '#1'
			},
      paperTitle: {
        type: String,
        default: '试卷标题'
      }
		},
		components: {
			radioSubject,
			checkSubject,
			shortAnswerSubject,
			programmingSubject,
			programmingSubjectMore
		},
		mounted() {
			// console.log(this.questionAndAnswer)
		},
		data() {
			return {
				isAnswer: [],
			}
		},
		methods: {
			scrollAnimation(currentY, targetY) {

				// 计算需要移动的距离
				let needScrollTop = targetY - currentY
				let _currentY = currentY
				setTimeout(() => {
					// 一次调用滑动帧数，每次调用会不一样
					const dist = Math.ceil(needScrollTop / 10)
					_currentY += dist
					document.getElementById("scrollDiv").parentNode.scrollTo(_currentY, currentY)
					// 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
					if (needScrollTop > 10 || needScrollTop < -10) {
						this.scrollAnimation(_currentY, targetY)
					} else {
						document.getElementById("scrollDiv").parentNode.scrollTo(0, _currentY)
					}
				}, 1)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.subjectTitle {
		background-color: #CCCCCC;
		height: 60px;

		p {
			line-height: 60px;
			margin-left: 20px;
		}
	}

	/deep/.el-card__header {
		background-color: #FFFFFF;
		border: none;
	}

	// /deep/.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover{
	// 	box-shadow: none!important;
	// }

	a {
		text-decoration: none !important;
	}

  .titleStyle {
    width: 100%;
    height: 50px;
    //background-color: #889AA4;
    border-radius: 10px;

    p {
      font-size: 22px;
      //font-weight: 500;
      line-height: 50px;
      color: #303133;
      text-align: center;
      border-radius: 10px;
    }
  }

	.titleType {
		width: 100%;
		height: 50px;
		background-color: #889AA4;
		border-radius: 10px;
		p {
			font-size: 28px;
			font-weight: 900;
			line-height: 50px;
			color: #EEEEEE;
			text-align: center;
			border-radius: 10px;
		}
	}


	/deep/.el-card {
		border: none;
		border-bottom: 1px solid #cccccc;
	}

	/deep/.el-card.is-always-shadow,
	.el-card.is-hover-shadow:focus,
	.el-card.is-hover-shadow:hover {
		box-shadow: none;
	}
</style>
