<template>
<!--	<div>-->
<!--		<p>试卷详情</p>-->
<!--	</div>-->
</template>

<script>
	export default {
		data() {
			return {}
		},
		mounted() {
			// this.startTime()
		},
		methods: {},
		beforeDestroy() {}
	}
</script>

<style lang="scss" scoped>

</style>
