<template>
  <div style="padding: 20px 10px 0 10px;">
    <p style="font-size: 18px;font-weight: 500;">{{ parperArr.name }}</p>
    <div style="display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: space-between;margin-top: 10px">
      <p style="font-size: 12px;">总分:{{ parperArr.score }}</p>
      <p style="font-size: 12px;">时长:{{ parperArr.suggestTime }}分钟</p>
    </div>
    <el-divider></el-divider>
    <!-- 		<div>
      <a :href="'#' + item" v-for="item in allNumberArr" :key="item">
        <el-button size="mini" type="infor" style="margin-left: 10px;margin-top: 10px;" class="subjetButton">
          {{item}}</el-button>
      </a>
    </div> -->

    <div v-for="(item,index) in allQuestionArr" :key="index">
      <div style="font-size: 12px;margin-bottom: 10px;margin-top: 10px;color: #34495e">
        {{item.name}}
      </div>
      <div>
        <!-- <a :href="'#' + item" v-for="item in allNumberArr" :key="item"> -->
        <a @click="clickanchor(`#q${item2}`)" v-for="item2 in item.questionOrder" :key="item2">
          <el-button size="mini" type="infor" style="margin-left: 4.5px;margin-top: 4.5px;" class="subjetButton">
            {{item2}}
          </el-button>
        </a>
      </div>
    </div>
    <div class="headerSty">
      <div style="margin-top: 20px;width: 100%;text-align:center;">
        <el-button size="mini" type="primary" @click="back()">退出</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    parperArr: {
      type: Object,
      default: () => ({})
    },
    allNumberArr: {
      type: Array,
      default: () => []
    },
    errorArr: {
      type: Array,
      default: () => []
    },
    allQuestionArr: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    errorArr: {
      handler: function(n, o) {
        let idArr = [];
        this.errorArr.forEach(item => {
          idArr.push(item)
        })
        this.$nextTick(() => {
          let butonArr2 = document.getElementsByClassName("subjetButton");
          butonArr2.forEach((item) => {
            let innerHTMLValue = item.getElementsByTagName("span")[0].innerHTML
            if (idArr.indexOf(parseInt(innerHTMLValue)) != -1) {
              item.classList.remove("el-button--infor"); // 设置
              item.classList.remove("el-button--success");
              item.classList.add("el-button--danger"); // 设置
            } else {
              item.classList.remove("el-button--danger"); // 设置
              item.classList.remove("el-button--success");
              item.classList.add("el-button--infor"); // 设置
            }
          })
        })
      },
      deep: true
    },
   },
  methods: {
    clickanchor(idName) {
      this.$emit("changeMaodian", idName);
      // document.querySelector(idName).scrollIntoView({
      // 	behavior: "smooth"
      // });
    },
    back() {
      // this.$router.go(-1)
      window.close()
    },
  },

  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  // margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  // padding-bottom: 10px;
}
</style>
